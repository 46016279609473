// 引入localStorage相关方法
import { getStorage, setStorage, cleanStorage } from "@/utils/myUtils";
// import * as APILOGIN from "@/api/login";
import router from '@/router'
/**
 * 人员信息模块（包含账号信息和员工信息）
 * @param {String} token：token值
 * @param {String} adminToken 超级管理员另存的token ，用于模拟登录等
 * @param {Object} adminUserInfo 超级管理员另存的userinfo
 * @param {Object} userInfo 用户信息
 * */ 
const pageModule = {
  state: {
    tabindex: getStorage("tabindex")?getStorage("tabindex"):0
  },
  mutations: {
    // 设置底部导航索引
    TABINDEX: (state, index) => {
      setStorage("tabindex", index,1);
      state.tabindex = index;
    },
  },
  actions: {
    
  }
};

export default pageModule;
