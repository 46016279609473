import { rootUrl, ajaxUrl } from "@/config/env";
import html2canvas from 'html2canvas';
import axios from "axios";
import store from "@/store";
import { Toast } from 'vant';
import { uploadUrl } from "@/config/env";
/**
 * 工具js集合
 */
/**
 * 时间戳 转换时间
 * @param {number} date:13位unix
 * @param {string} fmt:转换的格式 ["Y","M"]
 * */
export function formatDate(val, fmt = ["Y", "M", "D"]) {
  if (!val) {
    return "";
  }
  let date = new Date(parseInt(val) * 1000);
  let obj = {};
  let back = "";
  obj.Y = date.getFullYear() + "-";
  obj.M =
    date.getMonth() + 1 < 10
      ? "0" + (date.getMonth() + 1) + "-"
      : date.getMonth() + 1 + "-";
  obj.D =
    date.getDate() < 10 ? "0" + date.getDate() + " " : date.getDate() + " ";
  obj.h = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
  obj.m =
    date.getMinutes() < 10 ? ":0" + date.getMinutes() : ":" + date.getMinutes();
  obj.s =
    date.getSeconds() < 10 ? ":0" + date.getSeconds() : ":" + date.getSeconds();
  for (let item of fmt) {
    back = back + obj[item];
  }
  return back;
}
/**
 * 时间格式化 但该项目没用到
 * */
export function dateFormatter(formatter, date) {
  date = date ? new Date(date) : new Date();
  const Y = date.getFullYear() + "",
    M = date.getMonth() + 1,
    D = date.getDate(),
    H = date.getHours(),
    m = date.getMinutes(),
    s = date.getSeconds();
  return formatter
    .replace(/YYYY|yyyy/g, Y)
    .replace(/YY|yy/g, Y.substr(2, 2))
    .replace(/MM/g, (M < 10 ? "0" : "") + M)
    .replace(/DD/g, (D < 10 ? "0" : "") + D)
    .replace(/HH|hh/g, (H < 10 ? "0" : "") + H)
    .replace(/mm/g, (m < 10 ? "0" : "") + m)
    .replace(/ss/g, (s < 10 ? "0" : "") + s);
}


/**
 * padLeftZero() 为未满二位数的值添加0
 * */
function padLeftZero(str) {
  return ("00" + str).substr(str.length);
}

/**
 * getStorage:获取缓存
 * @param name：获取的名字
 * */
export function getStorage(name) {
  //根据名称获取缓存值 默认为空
  let [storageString = ""] = [localStorage.getItem(name)];
  //如果未设置该缓存或没有值，直接返回空串
  if (storageString == null || storageString.length <= 0) {
    return "";
  }
  try {
    // 把缓存字符串转为对象、获取当前时间
    let [storageObj, nowTime] = [
      JSON.parse(storageString),
      new Date().getTime()
    ];
    // 如果缓存对象不存在或为空，或者对象设置了时间值并且时间值小于当前时间(即已过期)，此时清除缓存
    if (
      !storageObj ||
      (storageObj.expires != undefined && storageObj.expires < nowTime)
    ) {
      cleanStorage(name);
      return null;
    } else {
      // 如果有值且时间未过期，则返回值，如果没有value则没有设置过期时间，此时不是对象，直接返回缓存字符串
      return storageObj.value || storageString;
    }
  } catch (e) {
    return storageString;
  }
}

/**
 * setStorage:设置缓存
 * @param name：设置的名字
 * @param value：设置的值
 * @param time：设置的过期时间 默认30天
 * */
export function setStorage(name, value, time = 30) {
  //获取过期时间的时间戳值和当前的时间戳
  let [days, nowTime] = [parseFloat(time) * 84000000, new Date().getTime()];
  //expires是过期时间
  localStorage.setItem(
    name,
    JSON.stringify({
      value: value,
      expires: nowTime + days
    })
  );
}

/**
 * cleanStorage:清除缓存
 * @param name：清除的名字
 * */
export function cleanStorage(name) {
  localStorage.removeItem(name);
}
/** setSessionStorage:设置缓存
 * @param {String} name：设置的名字
 * @param {*} value：设置的值
 * */
export function setSessionStorage(name, value) {
  sessionStorage.setItem(name, value);
}
/** getSessionStorage:获取缓存
 * @param {String} name：设置的名字
 * @return 返回session值
 * */
export function getSessionStorage(name) {
  return sessionStorage.getItem(name);
}
/**
 * cleanStorage:清除缓存
 * @param name：清除的名字
 * */
export function cleanSessionStorage(name) {
  sessionStorage.removeItem(name);
}

/**
 * 获取url参数
 * */
export function getQueryString(name) {
  var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
  var r = window.location.search.substr(1).match(reg); //这里返回找到正则的匹配

  if (r != null) {
    return unescape(r[2]); //这里返回对应的值
  } else {
    if (sessionStorage.wx_authorize_search) {
      var r1 = sessionStorage.wx_authorize_search.substr(1).match(reg);
      if (r1 != null) return unescape(r1[2]);
    }
  }
  return null;
}

/**
 * 截取并替换字符串
 * @param {String} value 需要替换的字符串
 * @param {number} start 开始位置
 * @param {Number} end 结束位置
 * @param {String} text  需要替换成的字符串
 *  */
export function formatter(value, start = 0, end = value.length, text) {
  // 如果结束位置小于等于开始位置，直接返回
  if (end <= start) {
    return value;
  }
  return value.replace(value.substring(start, end), text);
}

// 下载文件
// 模板下载
export  function downTemplate(url) {
  let a = document.createElement("a");
  a.href = ajaxUrl + url;
  a.click();
}
export  function downTemplate1(url) {
  let a = document.createElement("a");
  a.href = url;
  a.click();
}
export function upload(file) {
  console.log(file.file)
  // 此时可以自行将文件上传至服务器
  const formData = new FormData(); // 声明一个FormData对象
  formData.append("file", file.file);
  axios
    .post(
      `${uploadUrl}Apicommon/uploadimg`,
      formData,
      {
        headers: {
          "content-type": "multer/form-data",
        },
      }
    )
    .then((res) => {
      let that = this;
      if (res.data.errcode == 10002 || res.data.errcode == 10003) {
        Toast(res.data.msg);
        store.commit("CLEAN_USER_INFO");
        setTimeout(function () {
          that.$router.replace({ path: "/" });
        }, 1000);
        return;
      }
      Toast(res.data.info);
      let imgpath = `${ajaxUrl}/${res.data.path}`;
      return imgpath
    });
}

export function downTemplate2(imgsrc, name) {//下载图片地址和图片名
  var image = new Image();
  // 解决跨域 Canvas 污染问题
  image.setAttribute("crossOrigin", "anonymous");
  image.onload = function () {
    var canvas = document.createElement("canvas");
    canvas.width = image.width;
    canvas.height = image.height;
    var context = canvas.getContext("2d");
    context.drawImage(image, 0, 0, image.width, image.height);
    var url = canvas.toDataURL("image/png"); //得到图片的base64编码数据    
    var a = document.createElement("a"); // 生成一个a元素
    var event = new MouseEvent("click"); // 创建一个单击事件
    a.download = name || "photo"; // 设置图片名称
    a.href = url; // 将生成的URL设置为a.href属性
    a.dispatchEvent(event); // 触发a的单击事件
  };
  image.src = imgsrc;
}

//下载图片到本地
export function downloadIamge(imgsrc, name) {//下载图片地址和图片名
  var image = new Image();
  // 解决跨域 Canvas 污染问题
  image.setAttribute("crossOrigin", "anonymous");
  image.onload = function () {
    var canvas = document.createElement("canvas");
    canvas.width = image.width;
    canvas.height = image.height;
    var context = canvas.getContext("2d");
    context.drawImage(image, 0, 0, image.width, image.height);
    var url = canvas.toDataURL("image/png"); //得到图片的base64编码数据

    var a = document.createElement("a"); // 生成一个a元素
    var event = new MouseEvent("click"); // 创建一个单击事件
    a.download = name || "photo"; // 设置图片名称
    a.href = url; // 将生成的URL设置为a.href属性
    a.dispatchEvent(event); // 触发a的单击事件
  };
  image.src = imgsrc;
}


// 复制
export function copyText(txt, success = function () { }) {
  // if (document.getElementById("copy-text")) {
  //   return document.getElementById("copy-text");
  // }
  var idObject = document.getElementById('copy-text');
  if (idObject != null) idObject.parentNode.removeChild(idObject);
  var input = document.createElement("input");
  input.setAttribute("type", "text");
  input.setAttribute("disabled", "disabled");
  input.setAttribute("value", txt);
  input.setAttribute("id", "copy-text");
  input.setAttribute("style", "position:absolute;left:-510px;z-index:-1;top:50%;");
  document.body.appendChild(input);
  let copyHtml = document.getElementById("copy-text");
  copyHtml.select(); // 选择对象
  document.execCommand("Copy"); // 执行浏览器复制命令
  success()
}
export function copyText2(txt, success = function () { }) {
  var idObject = document.getElementById('copy-text');
  if (idObject != null) idObject.parentNode.removeChild(idObject);
  var dom = document.createElement("textarea");
  dom.style.fontSize = '12px';
  dom.style.border = '0';
  dom.style.padding = '0';
  dom.style.margin = '0';
  dom.style.position = 'absolute';
  dom.style['left'] = '-9999px';
  var yPosition = window.pageYOffset || document.documentElement.scrollTop;
  dom.style.top = yPosition + 'px';

  dom.setAttribute('readonly', '');
  dom.value = txt;
  document.body.appendChild(dom);
  dom.select();
  dom.setSelectionRange(0, dom.value.length);
  document.execCommand('copy');
  success()
}
