// 引入localStorage相关方法
import { getStorage, setStorage, cleanStorage } from "@/utils/myUtils";
// import * as APILOGIN from "@/api/login";
import router from '@/router'
/**
 * 人员信息模块（包含账号信息和员工信息）
 * @param {String} token：token值
 * @param {String} adminToken 超级管理员另存的token ，用于模拟登录等
 * @param {Object} adminUserInfo 超级管理员另存的userinfo
 * @param {Object} userInfo 用户信息
 * */ 
const userModule = {
  state: {
    token: getStorage("token"),
    adminToken: getStorage("adminToken"),
    adminUserInfo:getStorage("adminUserInfo"),
    userInfo: getStorage("userInfo")
  },
  mutations: {
    // 设置token
    SET_TOKEN: (state, token) => {
      setStorage("token", token,1);
      state.token = token;
    },
    // 设置账户信息缓存
    SET_USERMESSAGE: (state, data) => {
      setStorage("userInfo", data);
      state.userInfo = data;
    },
    // 设置超级管理员token
    SET_ADMINTOKEN:(state,token)=>{
      setStorage('adminToken',token,1);
      state.adminToken=token
    },
    // 设置超级管理员userInfo
    SET_ADMINTUSERINFO:(state,data)=>{
      setStorage('adminUserInfo',data,1);
      state.adminUserInfo=data
    },
    // 清除用户数据
    CLEAN_USER_INFO() {
      cleanStorage("token");
      cleanStorage("adminToken");
      cleanStorage("userInfo");
      cleanStorage("navindex");
    },
    // 把登录的token切换成企业端用的btoken 以免混乱/ 不用了 state
    CHANGE_BUSNESS() {}
  },
  actions: {
     // 模拟登录
     async simulationLogin({ commit, state }, data) {
      var adminToken = state.adminToken;
      commit("SET_TOKEN", adminToken);
      // let result = await APILOGIN.setlogin({ id: data.id });
      // 缓存用户信息
      commit("SET_USERMESSAGE", result.user_info);
      // 清除缓存的套餐详情
      commit("CREALPACKAGEDETAIL");
      window.location.href="/home/index"
      // 获取角色左侧菜单,因切换后一直有上次用户数据存留bug，先直接window跳转解决
      // getMenu(true).then(() => {
        // router.push({ path: "/home/index" });
      // });
      // ELEMENT.Message({
      //   message: '切换成功',
      //   type: "success"
      // });
    }
  }
};

export default userModule;
