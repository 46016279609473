
import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex);

import userModule from "./modules/userModule";
import pageModule from "./modules/pageModule";
const store = new Vuex.Store({
  elMain: { width: '', height: "" },
  mutations: {
    // 设置main组件宽高
    SET_ELMAIN: (state, data) => {
      state.elMain = data;
    }
  },
  modules:{
    userModule,
	pageModule
  }
})
//定义用户模块直接使用
Vue.prototype.$storeUserModule=store.state.userModule;
export default store;
