var [axiosUrl, imgUrl, rootUrl, adminUrl, ajaxUrl, codeUrl, axiosUrlPC, uploadUrl] = ["", "", "", "", "", '', '', ''];
// 正式环境

// 正式
const _AJAXURL_YY = "";
const _AJAXURLPC_YY = "";
const _DOMAINURL_YY = "";
const _WEBURL_YY = "";
const _CODEURL_YY = "";
const _UPLOADURL_YY = "";

// 测试
const _AJAXURL_CS = "";
// const _AJAXURL_CS = "";
const _AJAXURLPC_CS = "/";
const _DOMAINURL_CS = "/";
const _WEBURL_CS = "";
const _CODEURL_CS = "";
const _UPLOADURL_CS = "/";

if (process.env.NODE_ENV === "development") {
  // 
  axiosUrl = _AJAXURL_CS + "/";
  axiosUrlPC = _AJAXURLPC_CS;
  ajaxUrl = _AJAXURL_CS;
  imgUrl = _DOMAINURL_CS;
  rootUrl = _WEBURL_CS;
  adminUrl = _DOMAINURL_CS;
  codeUrl = _CODEURL_CS;
  uploadUrl = _UPLOADURL_CS;
} else {
  axiosUrl = _AJAXURL_YY + "/";
  axiosUrlPC = _AJAXURLPC_YY;
  ajaxUrl = _AJAXURL_YY;
  imgUrl = _DOMAINURL_YY;
  rootUrl = _WEBURL_YY;
  adminUrl = _DOMAINURL_YY;
  codeUrl = _CODEURL_YY;
  uploadUrl = _UPLOADURL_YY;
}
export { axiosUrl, imgUrl, rootUrl, adminUrl, ajaxUrl, codeUrl, axiosUrlPC, uploadUrl };

