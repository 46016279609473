<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  data() {
    return {
      show: false
    };
  },
  mounted() {},
  created() {},
  methods: {}
};
</script>
<style lang="scss">
</style>
