import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './assets/iconfont/iconfont.css';
import Vant from 'vant';
import { Toast } from 'vant';
import { Dialog } from 'vant';
import 'vant/lib/index.css';
import 'amfe-flexible';
import { Icon } from 'vant';
import Vconsole from 'vconsole';
import { ImagePreview } from 'vant';
import { Swipe, SwipeItem } from 'vant';

Vue.use(Swipe);
Vue.use(SwipeItem);
// new Vconsole();

Vue.use(Icon);
Vue.use(Dialog);
Vue.use(ImagePreview);
Vue.prototype.$toast = Toast;
Vue.prototype.$dialog = Dialog;
Vue.config.productionTip = false;
Vue.use(Vant);
//号码4位加密
Vue.prototype.$showMobile = function (val) {
  return String(val).substr(0, 3) + '****' + String(val).substr(7);
};
//跳转页面
Vue.prototype.openUrl = function (url) {
  this.$router.push(url);
};
router.beforeEach((to, from, next) => {
  console.log(to)
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})
router.afterEach(() => {
  // 使用Vue的$nextTick确保DOM已更新
  Vue.nextTick(() => {
    // 滚动到顶部
    window.scrollTo(0, 0);
  });
});
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
