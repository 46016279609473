import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    component: () => import('@/pages/index/index.vue'),
  },

];

const router = new VueRouter({
  // base: '/',
  // mode: 'history',
  routes,
});

export default router;
